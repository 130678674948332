.send-invites-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.send-invites-input-container {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.send-invites-input {
  width: 30rem;
}

.send-invites-add-email {
  width: fit-content;
}

.send-invites-text {
  font-size: 1rem;
}

.send-invites-tags-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.send-invites-separator {
  border: 0;
  border-bottom: 1px solid #d6d6d6;
}

.send-invites-credit-text {
  font-weight: bold;
  font-size: 1.25rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.send-invites-error-text {
  margin-top: 1rem;
  color: #ff0000;
}

.file-input {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
  margin-top: 1rem;
}

.file-input input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

@media (max-width: 50rem) {
  .send-invites-title {
    font-size: 1.125rem;
  }

  .send-invites-input-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    margin-top: 1rem;
  }

  .send-invites-tags-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0;
    align-items: stretch;
  }

  .send-invites-tag {
    display: flex;
    flex-grow: 1;
    max-width: 90%;
  }

  .send-invites-input {
    width: 90%;
    margin-bottom: 1rem;
  }

  .send-invites-text {
    margin-bottom: 2rem;
  }

  .send-invites-input-container {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .send-invites-credit-text {
    font-size: 1rem;
  }
}
