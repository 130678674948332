.dashboard-container {
  margin-top: 0.15rem;
  position: relative;
}

.dashboard-employer-title {
  font-size: 1.5rem;
  font-weight: bold;
  align-self: center;
}

.dashboard-mobile-menu-container {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
}

.menu-title {
  margin: 1rem 0 0 0;
  font-size: 1.125rem;
  padding-left: 1rem;
}

.menu-email {
  color: var(--secondary-text-color);
  font-size: 1rem;
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #d6d6d6;
}

.dashboard-tabs-container {
  width: 15rem;
  height: 100vh;
  border-right: 1px solid #d6d6d6;
}

.dashboard-menu {
  display: flex !important;
  align-items: center !important;
  gap: 0.5rem !important;
  font-size: 1.125rem;
  padding: 0.75rem 1rem !important;
  border-bottom: 1px solid #d6d6d6 !important;
  background: #ffffff;
  cursor: pointer;
}

.dashboard-menu:hover {
  background: #f0f0f0;
}

.dashboard-menu-disabled {
  cursor: default !important;
  color: #d6d6d6 !important;
}

.dashboard-menu-disabled:hover {
  background: #ffffff !important;
}

.dashboard-active-menu {
  background: rgba(155, 169, 232, 0.4) !important;
  cursor: default;
}

.dashboard-content {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.form-title {
  font-size: 1.25rem;
  color: var(--secondary-text-color);
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.dashboard-title {
  padding-top: 1rem;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
}

.dashboard-create-job {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

@media (max-width: 35rem) {
  .dashboard-title {
    font-size: 1.125rem;
  }

  .dashboard-employer-title {
    display: none;
  }
}

@media (max-width: 25rem) {
  .dashboard-title {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }

  .dashboard-create-job {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .form-title {
    font-size: 1.125rem;
  }
}
