.location-container {
  display: flex;
  flex-direction: column;
}

.location-text {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

.location-items-container {
  display: flex;
  justify-content: space-evenly;
  width: -webkit-fill-available;
  width: -moz-available;
  background: rgba(155, 169, 232, 0.4);
  padding: 2rem;
  margin-top: 1.5rem;
}

.location-item:not(:last-child) {
  margin-right: 2rem;
}

.location-index {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
}

.location-icon-container {
  position: relative;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid black;
  background: #d6d6d6;
}

.location-icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  position: absolute;
  top: 0.425rem;
  left: 0.425rem;
}

.location-item-text {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.5rem;
  text-align: center;
}

.location-text {
  margin-top: 1rem;
}

.location-question-title-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.location-form-check-label {
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
  color: #ff0000;
  font-weight: bold;
}

.location-form-check-label-disabled {
  color: #d6d6d6;
}
.location-dropdown {
  margin-top: 1rem;
}

.highlightOption {
  background: #ffff;
  color: #000;
}

@media (max-width: 1023px) {
  .location-container {
    display: flex;
    flex-direction: column;
  }

  .location-items-container {
    padding: 0.5rem;
  }

  .location-icon-container {
    width: 5rem;
    height: 5rem;
  }

  .location-icon {
    width: 4rem;
    height: 4rem;
  }
}

@media (max-width: 599px) {
  .location-items-container {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 1rem auto;
    padding: 1.5rem 3rem 3rem 3rem;
  }

  .location-item {
    display: flex;
    flex-direction: column;
  }

  .location-icon-container {
    align-self: center;
  }

  .location-index {
    margin-top: 1.5rem;
  }
}
.location-disabled {
  color: #d6d6d6;
}

@media (max-width: 25rem) {
  .location-items-container {
    padding: 1rem 1.5rem;
  }

  .location-icon-container {
    width: 4rem;
    height: 4rem;
  }

  .location-icon {
    width: 3rem;
    height: 3rem;
  }

  .location-index {
    font-size: 1rem;
  }

  .location-item-text {
    font-size: 1rem;
  }
}
