.view-response-configuration-container {
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.view-response-configuration-button {
  align-self: center;
  margin-top: 1.5rem;
  width: fit-content;
}

.view-response-edit-configuration {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 5rem;
}

.candidate-preferences-title {
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.candidate-preferences-title-deal-breaker {
  color: #ff0000;
}

@media (max-width: 25rem) {
  .view-response-configuration-container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
