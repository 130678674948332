.drafts-wrapper {
  position: relative;
}

.drafts-title {
  font-size: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
}

.drafts-no-drafts {
  margin-top: 1.5rem;
  font-size: 1.25rem;
  margin-left: 1.5rem;
}

.drafts-container {
  margin: 0 1.5rem;
}

.drafts-action-buttons-container {
  display: flex;
}

.drafts-delete-job-container {
  font-size: 1.125rem;
}

.drafts-delete-job-buttons-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.drafts-all-drafts-button {
  position: absolute;
  top: 0;
  left: 1.5rem;
  width: fit-content;
}

.drafts-edit-job-container {
  padding-top: 3.5rem;
  margin-left: 1.5rem;
}

@media (max-width: 35rem) {
  .drafts-title {
    font-size: 1.25rem;
  }
}

@media (max-width: 25rem) {
  .drafts-title {
    margin-left: 1rem;
  }

  .drafts-container {
    margin: 0 1rem;
  }
}
