.credits-container {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.credits-title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
}

.credits-balance-container {
  display: flex;
  justify-content: space-between;
  margin-right: 1.5rem;
}

.credits-text {
  font-size: 1.125rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.credits-add-credits-text {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
}

.credits-add-credits {
  margin-top: 1rem;
  width: fit-content;
  align-self: center;
}

.credits-transaction-card {
  position: relative;
  border: 1px solid #d6d6d6;
  margin: 0 1.5rem 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 8px rgba(206, 212, 240, 0.8);
  padding: 1rem;
}

.credits-transaction-type {
  font-size: 1.25rem;
  color: #0000cc;
  font-weight: bold;
  margin-bottom: 1rem;
}

.credits-transaction-date {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.credits-transaction-text {
  font-size: 1.125rem;
  margin-bottom: 1rem;
  color: var(--primary-text-color) !important;
}

.credits-transaction-spend-container {
  display: flex;
  gap: 2rem;
}

.credits-transaction-spend-text {
  text-align: center;
  color: #7b7b7b;
  font-weight: bold;
}

.credits-transaction-spend-icon {
  align-self: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #7b7b7b;
}

.credits-transaction-balances-container {
  display: flex;
  gap: 4.5rem;
  margin-top: 1rem;
}

.credits-pagination-container {
  margin-top: 1rem;
  align-self: center;
}

@media (max-width: 35rem) {
  .credits-title {
    font-size: 1.25rem;
  }

  .credits-text {
    font-size: 1rem;
  }

  .credits-transaction-type {
    font-size: 1.125rem;
  }

  .credits-transaction-text {
    font-size: 1rem;
  }

  .credits-balance-container {
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;
    margin-bottom: 1rem;
  }
}

@media (max-width: 25rem) {
  .credits-title {
    margin-left: 1rem;
  }

  .credits-text {
    margin-left: 1rem;
  }

  .credits-transaction-card {
    margin: 0 1rem 1rem 1rem;
  }

  .credits-transaction-spend-container {
    gap: 1rem;
  }
}
