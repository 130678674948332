.job-listing-details-container {
  margin: 1.5rem;
}

.job-listing-details-title {
  font-size: 1.125rem;
  font-weight: bold;
}

.job-listing-details-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 1.5rem;
}

.job-listing-details-icons {
  display: flex;
  justify-content: space-between;
  gap: 0 0.5rem;
}

.send-invite-dialog .MuiDialog-container .MuiPaper-root {
  width: 800px !important;
  max-width: 800px !important;
}

.view-response-dialog .MuiDialog-container .MuiPaper-root {
  width: 100% !important;
  max-width: 100% !important;
}

.job-listing-details-send-invite-buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1.5rem;
}

.job-listing-details-no-js {
  margin-top: 1.5rem;
  font-size: 1.125rem;
}

.job-listing-details-success-message {
  font-size: 1.125rem;
  font-weight: bold;
  color: #0000cc;
  margin: 1.5rem 0;
}

.feedback-input {
  margin-top: 1rem;
}

@media (max-width: 44rem) {
  .job-listing-details-title-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
