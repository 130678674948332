.create-job-title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.create-job-content {
  margin: 0 1rem 2rem 0;
}

.create-job-button-wrapper {
  display: flex;
  justify-content: space-around;
}

.create-job-error {
  margin: 1.5rem 0;
  color: #ff0000;
  font-size: 1rem;
}

.create-job-invites-sent-dialog {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.create-job-invites-sent-dialog-text {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

.create-job-invites-sent-dialog-button {
  align-self: center;
  margin-top: 1rem;
  width: fit-content;
}

.create-job-icon-button-container {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 1.5rem 0 1.5rem;
}

.create-job-continue {
  width: fit-content;
  margin-top: 1.5rem;
  align-self: center;
}

@media (max-width: 35rem) {
  .create-job-title {
    font-size: 1.25rem;
  }
}
