.track-jobs-title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
}

.track-jobs-all-jobs-button {
  width: fit-content;
  margin-left: 1.5rem;
}

.track-jobs-job-title {
  color: #0000cc;
  cursor: pointer;
  font-weight: 600;
}
