.job-details-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.job-details-input {
  width: 90%;
  margin-bottom: 2.5rem;
}

@media (max-width: 50rem) {
  .job-details-input {
    width: auto;
  }
}
