.tag {
  display: flex;
  gap: 0.5rem;
  border: 0.09375rem solid #4d6cd9;
  border-radius: 1rem;
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 0.25rem 0.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  word-wrap: break-word;
}

@media (max-width: 25rem) {
  .tag {
    font-size: 0.875rem;
  }
}
