.work-industry-checkbox-container {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
}

.work-industry-dropdown {
  max-width: 35rem;
  margin-top: 1rem;
}
