.login-input {
  margin-bottom: 2rem;
}

.login-error {
  color: var(--error-message-color);
  font-size: 1rem;
  margin-top: 1rem;
}

.login-otp-input {
  border-radius: 0.5rem;
  width: 2.625rem !important;
  margin: 1rem 1rem 1rem 0;
  height: 2.625rem;
  border: 0.09375rem solid var(--text-input-default-border);
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--text-input-active-color);
}

.login-submit {
  font-size: 1rem;
}

@media (max-width: 45rem) {
  .login-input {
    width: 90%;
  }
}

@media (max-width: 25rem) {
  .login-input {
    width: auto;
  }

  .login-error {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
