@import url("./themes/theme.css");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

body {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito", sans-serif;
  color: var(--primary-text-color);
  background: #e5e5e5;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.wrapper {
  max-width: 85.375rem;
  margin: 0 auto;
  background: #ffffff;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

.form-loading {
  margin: 10rem 50% 10rem 50%;
}

.form-error-message {
  font-size: 1rem;
  color: #ff0000;
  margin-top: 1rem;
}

input:disabled {
  cursor: default;
}

.loading {
  margin: 10rem 50% 10rem 50%;
}

.MuiSlider-thumb:last-child {
  margin-left: -1rem;
}

.MuiDialog-paper {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}

.MuiTableCell-sizeMedium {
  font-size: 1rem !important;
}

.title-border {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  padding: 0.5rem 0;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

.disable {
  font-weight: bold;
  position: absolute;
  right: 1rem;
}

.form-disable {
  font-weight: bold;
  align-self: flex-end;
  margin-top: 0;
  margin-right: 1.4rem;
  font-size: 1rem;
}

.form-disable input {
  margin-right: 0.4rem;
  cursor: pointer;
}

.formio-component-columns {
  display: flex;
}

@media (max-width: 1023px) {
  .form-radio {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

@media (max-width: 25rem) {
  .form-error-message {
    font-size: 0.875rem;
  }

  .title-border {
    margin-right: 1rem;
  }
}

@media (max-width: 371px) {
  .MuiTablePagination-displayedRows {
    display: none !important;
  }

  .MuiTablePagination-actions {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .disable {
    margin-top: 3rem;
  }

  .form-disable {
    margin-right: 0;
  }
}
